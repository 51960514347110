.public-profile-wrapper{
  .img-wrapper {
    position: relative;
    max-width: max-content;
    .middle {
      opacity: 0;
      transition: .5s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }

    .user-favicon {
      transition: .5s ease;

      backface-visibility: hidden;
    }

    &:not(.img-wrapper--current-user):hover {
      .user-favicon {
        opacity: 0.6;
      }

      .middle {
        opacity: 1;
      }
    }
  }
}