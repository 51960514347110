@import "../../Styles/variables";

.preview-profile {
  width: 50%;
  justify-content: center;
  margin: 0 auto;

  &:active {
    color: $white !important;
  }
}