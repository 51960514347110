@import "../../Styles/variables";

.app-notification-container {
  position: fixed;

  color: $black;
  background: rgb(0 0 0 / 53%);
  text-align: center;

  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  transition: top 0.7s ease-in-out, opacity 0.7s linear;
  font-family: JostRegular, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 10;

  .app-notification-message-container {
    width: 250px;
    height: 200px;
    padding: 18px;
    border-radius: 15px;
    background: $white;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .app-notification-message {
      font-size: 1.2rem !important;
      margin: auto;
      text-align: center !important;
    }

    .checkmark__circle_notification {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #7ac142 !important;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .checkmark_notification {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #7ac142;
      stroke-miterlimit: 10;
      margin: 10% auto;
      box-shadow: inset 0 0 0 #7ac142 !important;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    }

    .checkmark__check_notification {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes scale {
      0%, 100% {
        transform: none;
      }
      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }
    @keyframes fill {
      100% {
        box-shadow: inset 0 0 0 30px #7ac142 !important;
      }
    }
  }

  &.show {
    opacity: 1;
  }
}