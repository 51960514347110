@import "../../Styles/variables";

.welcome-wrapper {
  overflow: hidden;
  min-height: 100vh;
  margin: 0 auto;

  .section-welcome {
    background-color: $main-dark-gray;
    height: calc(100vh - 72px);
    padding: 70px 15px 48px;

    .welcome-title {
      font-size: 48px;
      font-family: PoppinsSemiBold;
      line-height: 62px;
      color: #fff;

      @media (max-width: 767px) {
        font-size: 37px;
        line-height: 45px;
      }

      .orange {
        font-family: PoppinsSemiBold;
        color: $main-orange;
      }
    }

    .welcome-subtitle {
      font-size: 16px;
      font-family: PoppinsMedium;
      line-height: 62px;
      color: #fff;
      text-transform: uppercase;
      margin-top: 8px;
    }

    video {
      max-width: 1028px;
      width: 100%;
      margin: auto 0;
    }

    .buttons {
      gap: 16px;

      @media (max-width: 575px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .btn-header {
        background-color: $blue-button;
        color: #FFF;
        text-decoration: none;
        padding: 14px 28px;
        font-size: 12px;
        font-family: PoppinsSemiBold;
        border-radius: 22px;

        @media (max-width: 575px) {
          width: 275px;
        }
      }

      .link-img img {
        height: 40px;
      }
    }
  }

  .section-2 {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 0 15px;

    @media (max-width: 991px) {
      height: auto;
      padding: 70px 15px;
    }

    .section-title {
      position: absolute;
      top: 100px;

      @media (max-width: 991px) {
        position: initial;
        margin-bottom: 30px;
      }
    }

    .element {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      text-align: center;
      display: flex;
      align-items: center;
      max-width: 1043px;
      width: 100%;
      padding: 0 15px;

      @media (max-width: 991px) {
        position: initial;
        transform: none;
        flex-direction: column;
        gap: 50px;
      }

      .section-2-text {
        max-width: 450px;
        display: flex;
        flex-direction: column;
        text-align: left;

        span {
          font-family: PoppinsRegular;
          font-size: 20px;
          line-height: 40px;

          & + span {
            margin-top: 15px;
          }

          span.bold {
            font-family: PoppinsSemiBold;
          }
        }
      }

      .section-2-img {
        max-height: 430px;
        margin-left: 140px;

        @media (max-width: 991px) {
          max-height: 330px;
          margin-left: 0;
        }
      }
    }
  }

  .section-3 {
    background-color: $main-dark-gray;
    padding: 100px 15px 0;
    text-align: center;
    overflow: hidden;

    @media (max-width: 575px) {
      padding: 70px 15px 0;
    }

    .bgc {
      width: 100%;
    }

    .section-info {
      max-width: 730px;
      color: #fff;
      font-family: PoppinsRegular;
      font-size: 20px;
      line-height: 40px;
      margin-top: 40px;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 38px;
      }
    }

    .section-subtitle {
      color: #fff;
      font-family: PoppinsSemiBold;
      font-size: 24px;
      line-height: 40px;
      margin-top: 20px;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 38px;
      }
    }
  }

  .section-4 {
    padding: 100px 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 991px) {
      padding: 70px 15px 115px;
    }

    .section-info {
      color: $main-dark-gray;
      font-family: PoppinsRegular;
      font-size: 20px;
      line-height: 40px;
      margin-top: 16px;

      @media (max-width: 991px) {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .features {
      margin: 60px 0 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      @media (max-width: 991px) {
        flex-direction: column;
        margin: 30px 0 70px;
        gap: 15px;
      }

      .feature {
        padding: 30px;
        background-color: #F8F8F8;
        border-radius: 8px;
        width: 460px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media (max-width: 991px) {
          max-width: 460px;
          width: 100%;
        }

        .feature-name {
          color: $main-dark-gray;
          font-family: PoppinsMedium;
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

    .screens {
      margin-top: 43px;
      padding: 0 45px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 60px;
      grid-row-gap: 0;

      @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .screen:first-child {
        margin-top: 140px;

        @media (max-width: 991px) {
          margin-top: 0;
          margin-bottom: 55px;
        }

        .screen-info {
          left: 29%;
          bottom: 13%;

          @media (max-width: 991px) {
            left: 50%;
            transform: translateX(-50%);
            bottom: -7%;
          }
        }
      }

      .screen:last-child {
        margin-top: 100px;

        @media (max-width: 991px) {
          margin-top: 45px;
        }

        .screen-info {
          right: 16%;
          bottom: -5%;

          @media (max-width: 991px) {
            right: 50%;
            transform: translateX(50%);
            bottom: -11%;
            width: 375px;
            white-space: wrap;
          }

          @media (max-width: 575px) {
            width: 300px;
          }
        }
      }

      .screen {
        position: relative;

        .screen-info {
          position: absolute;
          background-color: #FFEBDA;
          border-radius: 62px;
          border: 1px solid $main-dark-gray;
          padding: 15px 24px;
          white-space: nowrap;

          @media (max-width: 1199px) {
            padding: 10px 19px;
          }

          span {
            color: $main-dark-gray;
            font-family: PoppinsMedium;
            font-size: 16px;
            line-height: 32px;

            @media (max-width: 1199px) {
              font-size: 12px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .section-5 {
    padding: 76px 15px 276px;
    background: url("/Assets/images/earth_bg.png") center center/cover no-repeat, $main-dark-gray;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .section-subtitle {
      margin-top: 16px;
      font-size: 26px;
      line-height: 32px;
      font-family: PoppinsRegular;
      color: #fff;

      .semibold {
        font-family: PoppinsSemiBold;
      }
    }

    img {
      position: absolute;
      bottom: 0;

      &.iphone {
        right: 14%;

        @media (max-width: 1199px) {
          right: 2%;
        }

        @media (max-width: 575px) {
          right: initial;
          left: 2%;
        }
      }

      &.macbook {
        right: 28%;

        @media (max-width: 1199px) {
          right: 22%;
        }

        @media (max-width: 575px) {
          right: initial;
          left: 40%;
          transform: scale(1.4);
          bottom: 12px;
        }
      }
    }
  }

  .section-6 {
    padding: 115px 15px 60px;
    text-align: center;

    @media (max-width: 575px) {
      padding: 70px 15px;
    }

    .section-info {
      font-size: 20px;
      line-height: 32px;
      font-family: PoppinsRegular;
      max-width: 670px;
      text-align: center;

      .semibold {
        font-family: PoppinsSemiBold;
      }
    }

    .features {
      margin: 60px 0 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      @media (max-width: 958px) {
        flex-direction: column;
      }

      .feature {
        padding: 30px;
        background-color: $main-dark-gray;
        border-radius: 8px;
        width: 430px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media (max-width: 1199px) {
          padding: 30px 10px;
        }

        @media (max-width: 991px) {
          max-width: 430px;
          width: 100%;
        }

        .feature-name {
          color: #fff;
          font-family: PoppinsMedium;
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

    .buttons {
      margin-top: 90px;
      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 575px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .btn-header {
        background-color: $blue-button;
        color: #FFF;
        text-decoration: none;
        padding: 14px 28px;
        font-size: 12px;
        font-family: PoppinsSemiBold;
        border-radius: 22px;

        @media (max-width: 575px) {
          width: 275px;
        }
      }

      .link-img img {
        height: 40px;
      }
    }
  }

  .section-7 {
    padding: 80px 15px;
    background-color: $main-dark-gray;
    text-align: center;

    .section-title {
      background: -webkit-linear-gradient(45deg, #EE8933, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 991px) {
        font-size: 32px;
        line-height: 44px;
      }
    }
  }

  footer {
    padding: 90px 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    img {
      margin-bottom: 50px;
    }

    span {
      font-size: 12px;
      line-height: 25px;
      color: $main-dark-gray;
      font-family: PoppinsRegular;
    }
  }
}

.browser-btn {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  border: 1px solid $main-dark-gray;
  border-radius: 16px;
  width: fit-content;

  &:hover {
    border: 1px solid $main-dark-gray;
  }

  img {
    height: 39px;
    margin-right: 15px;
  }

  span {
    font-size: 16px;
    line-height: 16px;
    font-family: PoppinsSemiBold;
  }
}

.subtitle {
  font-size: 40px;
  line-height: 60px;
  font-family: PoppinsBold;
}

.section-title {
  font-size: 42px;
  line-height: 62px;
  font-family: PoppinsSemiBold;

  @media (max-width: 991px) {
    font-size: 32px;
    line-height: 38px;
  }

  .orange {
    color: $main-orange;
    font-family: PoppinsSemiBold;
  }
}

.section-title-white {
  font-size: 42px;
  line-height: 62px;
  font-family: PoppinsSemiBold;
  color: #fff;

  @media (max-width: 991px) {
    font-size: 32px;
    line-height: 38px;
  }

  .orange {
    color: $main-orange;
    font-family: PoppinsSemiBold;
  }
}

.text-gradient {
  background: -webkit-linear-gradient(270deg, rgba(239, 138, 52, 1) 0%, rgba(71, 24, 135, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-description {
  font-size: 24px;
  line-height: 36px;
  font-family: PoppinsMedium;
}

.divider {
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, rgba(239, 138, 52, 1) 0%, rgba(71, 24, 135, 1) 100%);
  margin-bottom: 47px;
}
