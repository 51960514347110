@import "../../../Styles/variables";

.welcome-header {
  width: 100%;
  transition: padding-top 0.35s ease-in-out;
  background-color: $main-dark-gray;
  height: 72px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 0 47px;

  @media (max-width: 767px) {
    height: 85px;
  }

  .logo-title {
    font-size: 16px;
    line-height: 23px;
    font-family: PoppinsSemiBold;
    color: #fff;
    margin-left: 14px;
  }

  .btn-header {
    background-color: $blue-button;
    color: #FFF;
    text-decoration: none;
    padding: 14px 28px;
    font-size: 12px;
    font-family: PoppinsSemiBold;
    border-radius: 22px;
  }

  .link-img img {
    height: 40px;
  }

  &.header-transparent {
    transition: padding-top 0.35s ease-in-out;
    box-shadow: none;
    padding-top: 50px;
  }
}
