// colors
$black: #0d0d0d;

$white: #ffffff;
$greyWhite: #f7fafc;
$black: #000000;
$basicBlue: #0e00d7;
$skyBlue: #8892b0;
$orange: #ff7200;
$light-orange: #f88d2c;
$light-orange-hover: #ff9e43;
$green: #00b017;
$light-green: #42d571;
$gray: #77838f;
$dark-gray: #3a3d3f;
$light-gray: #d9d9d9;
$skyGray: #d5e6fb;
$skyGray-hover: #ecf3fc;
$purple: #3e50e2;
$light-purple: #1977f3;
$red: #e41414;
$main-dark-gray: #1b1b1b;
$main-orange: #EE8933;
$blue-button: #0B57D0;

// fonts
$nunito: "Nunito", sans-serif;

// font weights
$thin: 100;
$exlight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$exbold: 800;
$exblack: 900;

// other variables
#popup .d-none, .auth-section .d-none {
  display: none !important;
}

.blinkAnimation {
  animation: blink 1.5s linear;
  animation-iteration-count: 1.5;
}

@keyframes blink {
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
}
