@import "../../../Styles/variables";

.comment-item-wrapper {
  .img-wrapper {
    position: relative;
    max-width: max-content;

    .middle {
      padding: .3rem;
      opacity: 0;
      transition: .5s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }

    .user-favicon {
      transition: .5s ease;
      backface-visibility: hidden;
    }

    &:not(.img-wrapper--current-user):hover {
      .user-favicon {
        opacity: 0.6;
      }

      .middle {
        opacity: 1;
      }
    }
  }

  .card {
    line-height: 1.2;
    text-decoration: none;

    &-title {
      font-weight: 700;
    }

    &-text{
      line-height: 1.5;
    }

    &-link {
      &:hover {
        color: $orange;

        .card-icon {
          filter: invert(53%) sepia(64%) saturate(4629%) hue-rotate(6deg) brightness(105%) contrast(98%);
        }
      }
    }
  }

  .mention{
    color:inherit;
    text-decoration: none;
    padding: 0 3px;
    border-radius: 15px;
    background-color: #d5e6fb;
    cursor: pointer;
  }
}


