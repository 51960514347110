.followers-wrapper{
  @media (max-width: 1199.98px) {
    .items{
      display: flex;
      justify-content: center;
    }
    h1{
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}