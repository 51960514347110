@import "../../Styles/variables";

.search-bar-wrapper {
  position: relative;

  .error {
    @media (max-width: 1199.9px) {
      outline: 2px solid #e41414;
      border-radius: 7px;

    }

    &-message {
      position: absolute;
      font-size: 0.9rem;
      width: max-content;
      top: -17px;
      right: 10px;
      color: #e41414;
    }
  }

  .search-bar {

    .input-group-text {
      cursor: pointer;
    }

    .form-control {
      box-shadow: none;
      outline-width: 0;

      @media (min-width: 1199.9px) {
        border: 0;
      }

      &::-webkit-search-cancel-button:hover,
      &::-webkit-search-decoration:hover {
        cursor: pointer;
      }
    }
  }

  .search-options {
    z-index: 1;
    position: absolute;
    transform: translateX(50%);
    top: 100%;
    right: 50%;

    display: flex;
    justify-content: center;

    margin-top: 2px;
    height: 55px;
    width: 200vw;
    transition: all 400ms ease-in, height 400ms ease-in;

    background-color: #3554de;
    color: $white;

    @media(max-width: 1199.9px) {
      width: 100%;
      background-color: transparent;
      color: $black;
    }

    .search-tab {
      width: 80px;
      text-align: center;
      text-decoration: none;
      padding: 1rem 0;
      cursor: pointer;

      &.active {
        color: $white;
        border-bottom: 4px solid $orange;
        @media(max-width: 1199.9px) {
          color: $orange;
        }
      }
    }

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transition: max-height 400ms ease-in-out;
      max-height: 0;
    }
  }
}

.mask {
  opacity: 1;
  visibility: visible;
  transition: visibility 0ms, opacity 300ms linear;
  position: fixed !important;
  top: 80px;
  left: 0;
  height: 100vw;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);

  &-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 300ms, opacity 300ms linear;
  }

  @media(max-width: 1199.9px) {
    display: none;
  }
}
