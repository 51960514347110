@import "../../Styles/variables";

#editProfile {
  .biography {
    h1 {
      font-size: 2.25rem;
    }

    p {
      padding: 0 15px;
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .textarea {
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }

  .userInfo {
    font-size: 2rem;

    input {
      font-size: 1.25rem;
      text-align: center;
      font-family: Nunito, sans-serif;
    }
  }

  .item-label {
    max-width: 100% !important;
    width: 100% !important;
    position: relative;
    display: block;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .field {
    width: 100% !important;
    height: 48px;
    border: 1px solid $light-gray;
    border-radius: 25px;
    padding: 10px 15px;
    background-color: #fff;
    color: #212529;
    font-family: "Jost", sans-serif;

    &.error {
      border-color: #e41414;
    }

    &-error {
      position: absolute;
      top: -16px !important;
      right: 20px;
      font-size: 13px;
      color: $red;
    }
  }

  .submitButton {
    height: 48px;
    border: 1px solid $light-gray;
    border-radius: 15px;
    padding: 10px 15px;
    background-color: $light-orange;
    color: $white;
    font-family: "Jost", sans-serif;
    font-size: 15px;
    transition: all 0.2s linear;

    &:active {
      background-color: $light-orange-hover !important;
      border: 0;

      transform: scale(0.9);

    }

    &:hover {
      background-color: $light-orange-hover;
      border: 0;
      transition: all 0.2s linear;
    }

    &:disabled {
      background-color: $orange;
      border: 0;

      filter: brightness(0.8);

      &:active {
        transform: unset;
      }
    }
  }
}

.crop-profile-photo-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000000c7;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  left: 0;
  top: 0;

  .config-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      padding: 14px;
      width: 100px;
    }
  }
}

