@import "../../../Styles/variables";

.terms-wrapper {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.2rem;
    padding: 0.5rem 0;
  }

  p {
    line-height: 1.5;
  }

  .last-updated {
    color: $gray;
  }
}