.spinner-border-wrapper {
  margin: 0 auto;
  width: 10rem;
  position: absolute;
  top: calc(50% - 80px);
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  color: #1977F3; //#3554de

  .spinner-border {
    border-width: 10px;
    width: 10rem;
    height: 10rem;
  }
}