@import "../../Styles/variables";

.card {
  line-height: 1.2;
  text-decoration: none;

  &-title {
    font-weight: 700;
  }

  &-link {
    &:hover {
      color: $orange;
      .card-icon {
        filter: invert(53%) sepia(64%) saturate(4629%) hue-rotate(6deg) brightness(105%) contrast(98%);
      }
    }
  }
}
