.welcome-footer {
  background-color: #59687c;
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;

  a {
    color: rgba(255, 255, 255, 0.85);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
