.period-container {
  & + & {
    margin-top: 15px;
  }

  .period-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.309px;
    color: #3a3d3f;
    opacity: 0.4;
  }
}
