@import "../../Styles/variables";

footer {
  min-height: 80px;
  background-color: #3554de;

  a.nav-link {
    color: $white;

    &:hover {
      text-decoration: underline;
      color: $white;
    }

    &.active {
      text-decoration: underline;
      color: $white;
    }
  }
}
