@import "../../Styles/variables";

.navbar {
  @media (max-width: 1400px) {
    font-size: 14px;
  }
  width:100vw;
  height: 80px;
  background-color: #3554de;

  &-toggler {
    border-color: $white;

    &:focus {
      box-shadow: none;
    }

    &-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  .offcanvas {
    a.nav-link {
      color: $white;
    }
  }

  &-nav {
    .dropdown-menu {
      padding: 0;
      margin: 0;
    }
  }
}

.offcanvas {
  a.nav-link {
    &:hover {
      text-decoration: underline;
    }

    &.active {
      text-decoration: underline;
    }
  }
}