@import "../../Styles/variables";

.expiration-message{
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  a{
    font-size: 3rem;
  }
  p{
    font-size: 4rem;
    font-weight: $semibold;
  }
}


