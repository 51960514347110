@import "../../../Styles/variables";

.contact-us-wrapper {
  font-size: 1.5rem;
  .contact-us-logo {
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 0 auto;
    width: max-content;
  }

  &-form {
    flex-grow: 1;

    .contact-label {
      line-height: 2;
      width: 100%;
      position: relative;
      display: block;
      cursor: pointer;
    }

    input[type] {
      font-size: 1.5rem;
    }

    textarea[type] {
      font-size: 1.5rem;
      min-height: 350px;
    }

    .field {
      width: 100%;
      height: 48px;
      border: 1px solid $light-gray;
      border-radius: 25px;
      padding: 10px 15px;
      background-color: #fff;
      color: #212529;
      font-family: "Jost", sans-serif;
      font-size: 15px;

      &.error {
        border-color: #e41414;
      }

      &-error {
        position: absolute;
        bottom: -20px;
        right: 20px;
        font-size: 14px;
        color: $red;
      }
    }
  }

}