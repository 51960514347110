.comment-wrap {
  margin-left: 16px;
  @media (min-width: 768px) {
    margin-left: 24px;
  }
  @media (min-width: 992px) {
    margin-left: 39px;
  }

  .replies {
    display: none;

    &-toShow {
      display: block;
    }
  }

  .loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;
    margin: .25rem auto -0.25rem auto;
    font-size: 1.1rem;
    font-style: italic;
    background-color: transparent;
    color: #1977F3;

    &:hover {
      background-color: transparent;
      color: #1977F3;
      opacity: 0.7;
    }

    &:focus {
      background-color: transparent !important;
      color: #1977F3 !important;
      opacity: 0.7;
    }

    img {
      margin-right: 2px;
    }
  }

  .blinkAnimation {
    animation: blink 1.5s linear;
    animation-iteration-count: 1.5;
  }

  @keyframes blink {
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.5;
    }
  }
}