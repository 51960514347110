.replies {
  margin-left: 16px;
  @media (min-width: 768px) {
    margin-left: 24px;
  }
  @media (min-width: 992px) {
    margin-left: 39px;
  }
}

.back-btn {
  margin-bottom: 10px;
  background-color: #3554de;
  border-color: #3554de;
  &:hover{
    background-color: #3554de;
  }
  &:active{
    opacity: 0.9;
    background-color: #3554de!important;
    color: #3554de!important;
  }
}