@import "../../../Styles/variables";

.category-link {

  .dropdown-toggle {
    background-color: transparent;
    border: none;
    display: flex;

    &:empty::after {
      color: black;
      padding: 0;
      margin-top: 6px;
      margin-left: -7px;
    }

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  .accordion {
    &-button {
      padding: 0;

      &::after {
        display: none;
      }
    }
  }

  .dropdown-item {
    &:hover > .dropdown-menu {
      display: block !important;
    }
  }

  .subcategory-dropdown {
    z-index: 2 !important;
    min-width: min-content;
    border: 1px solid $light-gray;
    border-bottom: 0;
    border-radius: 0;

    .subcategory {
      border-bottom: 1px solid $light-gray;
      position: relative;
      background-color: inherit;

      .dropdown-toggle {
        background-color: transparent;
        border: none;
        display: flex;

        &:empty::after {
          transform: rotate(-90deg);
          color: black;
          padding: 0;
          margin-top: 1px;
          margin-left: -12px;
        }

        &:hover {
          background-color: transparent;
        }

        &:active {
          background-color: transparent !important;
        }
      }

      &:hover > .dropdown-menu {
        display: block !important;
      }

      .dropdown-menu {
        border: 1px solid $light-gray;
        border-bottom: 0;
        border-radius: 0;
        position: absolute;
        top: -1px;
        left: 100%;
      }
    }

    a.nav-link {
      color: $black;

      &:hover {
        color: $black;
      }
    }
  }

  &:hover {
    .subcategory-dropdown {
      @media (min-width: 1199.9px) {
        display: block !important;
      }
    }
  }
}

