.notification-container {
  position: relative;
  margin-top: 20px;
  cursor: pointer;

  .no-photo{
    margin-left: -3px;
    scale: (1.2);
  }
  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .notification-text {
    max-width: calc(100% - 100px);
    color: #3a3d3f;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.309px;

    .notification-date {
      color: #77838f;
      padding-top: 5px;
    }
  }
  &:hover{
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
}

.notification-item-new-notification {
  background: #42d571;
  width: 15px;
  height: 15px;
  position: absolute;
  overflow: hidden;
  border-radius: 80%;
  right: 10px;
  top: -30px;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: opacity 10s ease-in-out;

  &.new {
    opacity: 1;
  }
}