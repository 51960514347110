@import "../../../Styles/variables";
.search-results {
  z-index: 1;
  position: absolute;
  top: calc(100% + 57px); //height+border of .search-options + 100%
  right: 50%;
  transform: translateX(50%);

  width: 320px; //width of .search-tab * q-ty of tabs
  max-height: 350px;
  display: grid;
  grid-auto-rows: auto;

  transition: visibility 0ms, opacity 300ms linear;
  background-color: $greyWhite;
  border: 1px solid $light-gray;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 300ms, opacity 300ms linear;
  }

  .item {
    height: 70px;
    grid-row: span 2;
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    padding: 1rem;
    transition: background-color 200ms ease-in;

    &-no-results {
      &:hover {
        background-color: inherit !important;
      }
    }

    .info {
      margin-right: 5px;
      min-width: 40px;
      max-width: 50px;
      text-align: center;

      p {
        font-weight: $semibold;
        color: $black;
        font-size: 0.8rem;
      }

      img {
        border-radius: 9999px;
      }
    }

    &-content {
      max-width: 240px;

      p {
        font-weight: $semibold;
        color: $black;
        font-size: 0.8rem;
      }
    }

    &:hover {
      transition: background-color 200ms ease-in;
      background-color: $skyGray;
    }
  }

  .show-all {
    height: 35px;
    width: 100%;
    background-color: transparent;
    border-bottom: none;
    text-align: center;
    transition: background-color 200ms ease-in;

    &:hover {
      transition: background-color 200ms ease-in;
      background-color: $skyGray;
    }
  }
}
