@import "../../../Styles/variables";

.notification-wrapper {
  position: relative;

  .badge {
    transition: opacity 1000ms ease-in-out;
    opacity: 0;
    position: absolute;
    top: -2px;
    left: 12px;
    display: block;
    background-color: #24ff24 !important;
    width: 10px;
    height: 10px;
    padding: 0;

    &.unread {
      opacity: 1;
    }
  }
}

#dropdownUser {
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;

  &:empty::after {
    color: black;
    padding: 0;
    margin-top: 6px;
    margin-left: -7px;
  }

  &:hover {
    background-color: transparent;
  }

  &:active {
    background-color: transparent !important;
  }

  &.show {
    background-color: transparent;
  }
}

a.dropdown-item {
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    color: $black;
    background-color: transparent;
  }

  &.active {
    color: $black;
    background-color: transparent;
    text-decoration: underline;
  }
}