@import "~bootstrap/scss/bootstrap.scss";
@import "./Styles/fonts";
@import "./Styles/reset";
@import "./Styles/variables";

html,
body,
#root {
  height: 100%;
}

main {
  padding-top: 90px;
}

.wrapper {
  min-height: 100%;
}

.btn.btn-orange {
  background-color: $orange;
  color: $white;
  border: none;

  &:hover {
    background-color: $light-orange-hover;
  }
}


.item-label {
  position: relative;
  display: block;
  width: 385px;
  cursor: pointer;
  @media (max-width: 400px) {
    width: 100%;
  }
}

.field {
  width: 100%;
  height: 48px;
  border: 1px solid $light-gray;
  border-radius: 25px;
  padding: 10px 15px;
  background-color: #fff;
  color: #212529;
  font-family: "Nunito", sans-serif;
  font-size: 15px;

  &.error {
    border-color: #e41414;
  }

  &-error {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
    color: $red;
  }
}

.btn-auth {
  position: relative;
  display: inline-block;
  min-width: 180px;
  padding: 15px 10px;
  color: $white;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  background-color: $light-orange;
  border-radius: 40px;
  transition: all 0.2s linear;

  @media (max-width: 425px) {
    width: 100%;
  }
}

.buttons-wrap {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  max-width: 385px;
}

.second-btn {
  color: #212529;
  background-color: $white;
  border: 1px solid $light-gray;
}

.forgot-password-container {
  max-width: 385px;

  @media (max-width: 425px) {
    text-align: center;
  }
}

.forgot-password {
  color: #3554de;
  text-decoration: none;
}


