@import url("https://fonts.googleapis.com/css?family=Nunito:200,400,600");
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: SF-Pro-Text-Regular;
  src: url("../Assets/fonts/SF-Pro-Text-Regular.otf");
}
@font-face {
  font-family: SofiaProLight;
  src: url("../Assets/fonts/SofiaProLight.woff");
}
@font-face {
  font-family: SofiaProMedium;
  src: url("../Assets/fonts/SofiaProMedium.woff");
}
@font-face {
  font-family: SofiaProRegular;
  src: url("../Assets/fonts/SofiaProRegular.woff");
}
@font-face {
  font-family: MulishMedium;
  src: url("../Assets/fonts/Mulish/static/Mulish-Medium.ttf");
}
@font-face {
  font-family: PoppinsBold;
  src: url("../Assets/fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: PoppinsSemiBold;
  src: url("../Assets/fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: PoppinsMedium;
  src: url("../Assets/fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: PoppinsRegular;
  src: url("../Assets/fonts/Poppins/Poppins-Regular.ttf");
}
