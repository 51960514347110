@import "../../../Styles/variables";

.policy-wrapper {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.3rem;
    padding: 0.5rem 0;
  }

  p {
    line-height: 1.5;
  }

  .strong {
    line-height: 2;
    font-size: 1.2rem;
    text-decoration: underline;
  }
}